<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="itemPaginate"
          :options.sync="options"
          :server-items-length="items.length"
          class="elevation-1 keep-on-scroll-table"
          :loading-text="$t('table.messages.loading')"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 100],
            showFirstLastPage: false,
            'items-per-page-text': $t('table.messages.items_per_page'),
          }"
          fixed-header
          :no-data-text="$t('table.messages.no_data')"
        >
          <template v-slot:footer.page-text="props">
            {{ props.pageStart }} - {{ props.pageStop }}
            {{ $t("table.headers.page-text") }}
            {{ props.itemsLength }}
          </template>
          <template v-slot:top>
            <v-container class="mt-5">
              <v-row class="infoWarp">
                <v-col cols="4">
                  <v-row>
                    <v-col cols="2">
                      <div class="lblText">
                        <h3>{{ $t("messages.factoryName") }}</h3>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <div class="align-self-center mr-9 zindex-10000">
                        <v-autocomplete
                          v-model="customer"
                          :items="listCustomer"
                          :item-text="
                            (item) =>
                              item.code == null
                                ? item.name
                                : item.code + ' - ' + item.name
                          "
                          item-value="id"
                          :label="$t('messages.chooseFactory')"
                          :no-data-text="$t('table.messages.no_data')"
                          clearable
                          single-line
                          outlined
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="2">
                      <div class="lblText">
                        <h3>{{ $t("messages.job") }}</h3>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <div class="align-self-center mr-9">
                        <v-select
                          v-model="job"
                          :disabled="!customer"
                          :items="listJob"
                          item-text="name"
                          item-value="id"
                          :label="$t('messages.chooseDepartment')"
                          multiple
                          outlined
                          :no-data-text="$t('table.messages.no_data')"
                        >
                          <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @click="toggle"
                            >
                              <v-list-item-action>
                                <v-icon :color="job.length > 0 ? 'indigo darken-4' : ''">
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ $t('messages.all') }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2" />
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 2">
                              <span>{{ item.name }}</span>
                            </v-chip>
                            <span
                              v-if="index === 2"
                              class="grey--text text-caption"
                            >(+{{ listJob.length - 2 }} その他)</span>
                          </template>
                        </v-select>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="2">
                      <div class="lblText">
                        <h3>{{ $t("messages.period") }}</h3>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <div class="align-self-center mr-9">
                        <v-select
                          v-model="period"
                          :items="getPeriod()"
                          outlined
                          item-text="name"
                          item-value="value"
                          :label="$t('messages.choosePeriod')"
                          :no-data-text="$t('table.messages.no_data')"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="infoWarp">
                <v-col cols="6">
                  <v-row>
                    <v-col
                      cols="5"
                      class="text-right"
                    >
                      <v-btn
                        large
                        block
                        color="forth"
                        class="mb-5 pt-0 white--text btn-import-csv"
                        to="/time-sheet-employee"
                      >
                        <span class="font-weight-bold text-h5">
                          {{ $t("messages.TimeSheetEmployee") }}</span>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="5"
                      class="text-right"
                    >
                      <v-btn
                        large
                        block
                        color="forth"
                        class="mb-5 pt-0 white--text btn-import-csv"
                        to="/time-sheet-department"
                      >
                        <span class="font-weight-bold text-h5">
                          {{ $t("messages.TimeSheetDepartment") }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row class="d-flex justify-center">
                    <v-col cols="6">
                      <div class="align-self-center mr-9">
                        <v-text-field
                          id="parttimerManageId"
                          v-model="parttimerManageId"
                          name="search pa-0 "
                          outlined
                          clearable
                          :placeholder="$t('messages.searchTextTimeManagement')"
                          @click:clear="clear"
                          @keydown.enter="search"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex align-end p-0"
                    >
                      <div class="mb-9">
                        <v-btn
                          large
                          block
                          color="primary"
                          @click="search"
                        >
                          <span class="pr-5 pl-5 font-weight-bold text-h5">
                            {{ $t("table.messages.search") }}</span>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:body="{ items }">
            <tbody v-if="itemPaginate.length > 0">
              <template v-for="(item, index) in items">
                <tr
                  v-for="(title, index2) in titles"
                  :key="title[index]"
                >
                  <td
                    v-if="index2 === 0"
                    :rowspan="isOtNightShift ? 10 : 9"
                    class="sticky-col keep-on-scroll"
                  >
                    {{ getIndex(index) }}
                  </td>
                  <td
                    v-if="index2 === 0"
                    :rowspan="isOtNightShift ? 10 : 9"
                    class="sticky-col text-center keep-on-scroll body-keep-on-scroll-2"
                  >
                    {{ item.manageId ? item.manageId : "" }}
                  </td>
                  <td
                    v-if="index2 === 0"
                    :rowspan="isOtNightShift ? 10 : 9"
                    class="sticky-col text-start keep-on-scroll body-keep-on-scroll-3"
                  >
                    {{ item.name ? item.name : "" }}
                  </td>
                  <td
                    :class="
                      index2 < titles.length - 1 ? 'time-col' : 'bottom-col'
                    "
                    class="right-border-col sticky-col keep-on-scroll body-keep-on-scroll-4"
                  >
                    <span :class="title.class ? title.class : ''">
                      {{ title.name ? title.name : "" }}
                    </span>
                  </td>
                  <template v-for="timesheet in item.timesheet">
                    <template v-for="(day, dayIndex) in timesheet.weekDays">
                      <td
                        v-if="
                          checkDayWeek(
                            dayIndex,
                            timesheet.weekStart,
                            timesheet.weekEnd
                          )
                        "
                      >
                        <template v-if="day.attendenceRecords.length === 0">
                          <template
                            v-if="title.id === 'attendance_confirmation'"
                          >
                            <div
                              class="
                                rowAttendenceRecords
                                attendanceConfirmation
                              "
                            />
                          </template>
                        </template>
                        <tempalte v-else>
                          <template
                            v-if="title.id === 'attendance_confirmation'"
                          >
                            <div
                              class="
                                rowAttendenceRecords
                                attendanceConfirmation
                              "
                            >
                              <div
                                v-for="(attendenceRecords, attendenceRecordsIndex) in day.attendenceRecords"
                                :key="attendenceRecordsIndex"
                                :class="{
                                  'right-border-col':
                                    attendenceRecordsIndex < day.attendenceRecords.length - 1 &&
                                    day.attendenceRecords.length > 1,
                                }"
                              >
                                <span>
                                  <i
                                    v-if="attendenceRecords.isPresent"
                                    class="
                                      attendancConfirm
                                      mdi mdi-radiobox-marked
                                    "
                                  />
                                  <i
                                    v-else
                                    class="mdi mdi-window-close"
                                  />
                                </span>
                              </div>
                            </div>
                          </template>
                          <template v-if="title.id === 'actualStartAt'">
                            <div
                              class="rowAttendenceRecords justify-center"
                              :class="{
                                attendanceConfirmation:
                                  day.attendenceRecords.length === 1,
                              }"
                            >
                              <div
                                v-for="(
                                  attendenceRecords, attendenceRecordsIndex
                                ) in day.attendenceRecords"
                                :key="attendenceRecordsIndex"
                                :class="{
                                  'right-border-col':
                                    attendenceRecordsIndex < day.attendenceRecords.length - 1 &&
                                    day.attendenceRecords.length > 1,
                                }"
                              >
                                <span
                                  v-if="attendenceRecords.isPresent"
                                >
                                  {{
                                    attendenceRecords.actualStartAt
                                      ? fomatDateTime(
                                        attendenceRecords.actualStartAt, attendenceRecords.actualStartAt
                                      )
                                      : "00:00"
                                  }}
                                </span>
                                <span v-else />
                              </div>
                            </div>
                          </template>
                          <template v-if="title.id === 'actualEndAt'">
                            <div
                              class="rowAttendenceRecords justify-center"
                              :class="{
                                attendanceConfirmation:
                                  day.attendenceRecords.length === 1,
                              }"
                            >
                              <div
                                v-for="(
                                  attendenceRecords, attendenceRecordsIndex
                                ) in day.attendenceRecords"
                                :key="attendenceRecordsIndex"
                                :class="{
                                  'right-border-col':
                                    attendenceRecordsIndex < day.attendenceRecords.length - 1 &&
                                    day.attendenceRecords.length > 1,
                                }"
                              >
                                <span
                                  v-if="attendenceRecords.isPresent"
                                >
                                  {{
                                    attendenceRecords.actualEndAt
                                      ? fomatDateTime(
                                        attendenceRecords.actualEndAt, attendenceRecords.actualStartAt
                                      )
                                      : "00:00"
                                  }}
                                </span>
                                <span v-else />
                              </div>
                            </div>
                          </template>
                          <template v-if="title.id === 'normalWorkTime'">
                            <div
                              class="rowAttendenceRecords justify-center"
                              :class="{
                                attendanceConfirmation:
                                  day.attendenceRecords.length === 1,
                              }"
                            >
                              <div
                                v-for="(
                                  attendenceRecords, attendenceRecordsIndex
                                ) in day.attendenceRecords"
                                :key="attendenceRecordsIndex"
                                :class="{
                                  'right-border-col':
                                    attendenceRecordsIndex < day.attendenceRecords.length - 1 &&
                                    day.attendenceRecords.length > 1,
                                }"
                              >
                                <span
                                  v-if="attendenceRecords.isPresent"
                                >
                                  {{
                                    attendenceRecords.normalWorktime
                                      ? converTime(attendenceRecords.normalWorktime)
                                      : '00:00'
                                  }}
                                </span>
                                <span v-else />
                              </div>
                            </div>
                          </template>
                          <template v-if="title.id === 'nightWorkTime'">
                            <div
                              class="rowAttendenceRecords justify-center"
                              :class="{
                                attendanceConfirmation:
                                  day.attendenceRecords.length === 1,
                              }"
                            >
                              <div
                                v-for="(
                                  attendenceRecords, attendenceRecordsIndex
                                ) in day.attendenceRecords"
                                :key="attendenceRecordsIndex"
                                :class="{
                                  'right-border-col':
                                    attendenceRecordsIndex < day.attendenceRecords.length - 1 &&
                                    day.attendenceRecords.length > 1,
                                }"
                              >
                                <span
                                  v-if="attendenceRecords.isPresent"
                                >
                                  {{
                                    attendenceRecords.nightShiftWorktime
                                      ? converTime(attendenceRecords.nightShiftWorktime)
                                      : '00:00'
                                  }}
                                </span>
                                <span v-else />
                              </div>
                            </div>
                          </template>
                          <template v-if="title.id === 'workOvertime'">
                            <div
                              class="rowAttendenceRecords justify-center"
                              :class="{
                                attendanceConfirmation:
                                  day.attendenceRecords.length === 1,
                              }"
                            >
                              <div
                                v-for="(
                                  attendenceRecords, attendenceRecordsIndex
                                ) in day.attendenceRecords"
                                :key="attendenceRecordsIndex"
                                :class="{
                                  'right-border-col':
                                    attendenceRecordsIndex < day.attendenceRecords.length - 1 &&
                                    day.attendenceRecords.length > 1,
                                }"
                              >
                                <span
                                  v-if="attendenceRecords.isPresent"
                                >
                                  {{
                                    attendenceRecords.otWorktime
                                      ? converTime(attendenceRecords.otWorktime)
                                      : '00:00'
                                  }}
                                </span>
                                <span v-else />
                              </div>
                            </div>
                          </template>
                          <template v-if="isOtNightShift && title.id === 'nightOtTime'">
                            <div
                              class="rowAttendenceRecords justify-center"
                              :class="{
                                attendanceConfirmation:
                                  day.attendenceRecords.length === 1,
                              }"
                            >
                              <div
                                v-for="(
                                  attendenceRecords, attendenceRecordsIndex
                                ) in day.attendenceRecords"
                                :key="attendenceRecordsIndex"
                                :class="{
                                  'right-border-col':
                                    attendenceRecordsIndex < day.attendenceRecords.length - 1 &&
                                    day.attendenceRecords.length > 1,
                                }"
                              >
                                <span
                                  v-if="attendenceRecords.isPresent"
                                >
                                  {{
                                    attendenceRecords.otNightShiftWorktime
                                      ? converTime(attendenceRecords.otNightShiftWorktime)
                                      : '00:00'
                                  }}
                                </span>
                                <span v-else />
                              </div>
                            </div>
                          </template>
                          <template v-if="title.id === 'breakHour'">
                            <div
                              class="rowAttendenceRecords justify-center"
                              :class="{
                                attendanceConfirmation:
                                  day.attendenceRecords.length === 1,
                              }"
                            >
                              <div
                                v-for="(
                                  attendenceRecords, attendenceRecordsIndex
                                ) in day.attendenceRecords"
                                :key="attendenceRecordsIndex"
                                :class="{
                                  'right-border-col':
                                    attendenceRecordsIndex < day.attendenceRecords.length - 1 &&
                                    day.attendenceRecords.length > 1,
                                }"
                              >
                                <span
                                  v-if="attendenceRecords.isPresent"
                                >
                                  {{
                                    attendenceRecords.breaktime
                                      ? converTime(attendenceRecords.breaktime)
                                      : converTime(0)
                                  }}
                                </span>
                                <span v-else />
                              </div>
                            </div>
                          </template>
                          <span v-if="title.id === 'workingHour'">
                            {{ day.totalWorkHour ? converTime(day.totalWorkHour) : '00:00' }}
                          </span>
                          <span v-if="title.id === 'cumulativeTime'">
                            {{
                              day.accumulateWorkHour
                                ? converTime(day.accumulateWorkHour)
                                : "00:00"
                            }}
                          </span>
                        </tempalte>
                      </td>
                    </template>
                    <td
                      v-if="index2 === 0"
                      :rowspan="isOtNightShift ? 10 : 9"
                      class="total-col"
                    >
                      {{
                        timesheet.totalWorkHour ? converTime(timesheet.totalWorkHour) : '00:00'
                      }}
                    </td>
                  </template>
                  <td>
                    <span v-if="title.id === 'attendance_confirmation'">
                      {{ item.totalWorkday ? item.totalWorkday : 0 }}
                      {{ $t("table.titles.day") }}
                    </span>
                    <span v-if="title.id === 'normalWorkTime'">
                      {{ item.totalNormalWork ? converTime(item.totalNormalWork) : '00:00' }}
                    </span>
                    <span v-if="title.id === 'nightWorkTime'">
                      {{ item.totalNightWork ? converTime(item.totalNightWork) : '00:00' }}
                    </span>
                    <span v-if="isOtNightShift && title.id === 'nightOtTime'">
                      {{ item.totalOtNightShift ? converTime(item.totalOtNightShift) : '00:00' }}
                    </span>
                    <span v-if="title.id === 'breakHour'">
                      {{ item.totalBreaktime ? converTime(item.totalBreaktime) : '00:00' }}
                    </span>
                    <span v-if="title.id === 'workOvertime'">
                      {{ item.totalOtWork ? converTime(item.totalOtWork) : '00:00' }}
                    </span>
                    <span v-if="title.id === 'workingHour'">
                      {{ item.totalWorkHour ? converTime(item.totalWorkHour) : '00:00' }}
                    </span>
                  </td>
                  <td
                    v-if="index2 === 0"
                    :rowspan="isOtNightShift ? 10 : 9"
                    class="total-col"
                  >
                    <span>0</span>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="100%">
                  {{ $t("error.no_data") }}
                </td>
              </tr>
            </tbody>
          </template>
          <template slot="no-data">
            {{ $t("table.messages.no_data") }}
          </template>
          <template v-slot:footer.prepend />
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'

  export default {
    name: 'TimesheetDisplay',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        selected: {},
        singleSelect: true,
        options: {},
        headers: [],
        editedIndex: -1,
        items: [],
        currentOptions: {},
        parttimerManageId: '',
        loading: false,
        totalItems: 1,
        headerDays: [],
        titles: [],
        customer: null,
        isOtNightShift: null,
        job: [],
        listJob: [],
        listCustomer: [],
        itemPaginate: [],
        period: null,
        mainHeaders: [],
      }
    },
    computed: {
      ...get('timesheet', [
        'list',
        'message',
        'status',
        'error',
        'customers',
        'jobs',
      ]),
      customerInfo: get('customer@customerDetail'),
      likesAllJob () {
        return this.job.length === this.listJob.length
      },
      likesSomeJob () {
        return this.job.length > 0 && !this.likesAllJob
      },
      icon () {
        if (this.likesAllJob) return 'mdi-close-box'
        if (this.likesSomeJob) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
    watch: {
      list (value) {
        this.$set(this, 'items', value.data.timesheets)
        this.itemPaginate = this.paginate(
          value.data.timesheets,
          this.options.itemsPerPage,
          this.options.page,
        )
      },
      customers (value) {
        this.$set(this, 'listCustomer', value.data.customers)
      },
      options: {
        handler () {
          this.itemPaginate = this.paginate(
            this.items,
            this.options.itemsPerPage,
            this.options.page,
          )
        },
        deep: true,
      },
      jobs (value) {
        this.$set(this, 'listJob', value.data.jobs)
      },
      customerInfo (value) {
        this.isOtNightShift = value.isOtNightShift
        this.getTitles()
      },
      customer (value) {
        this.job = []
        if (value) {
          this.getJobs(value)
          this.$store.dispatch('customer/getCustomer', {
            id: value,
          })
        } else {
          this.listJob = []
        }
        const payload = {}

        if (this.parttimerManageId.length > 0) {
          payload.parttimerManageId = this.parttimerManageId
        }

        if (this.period && this.customer && this.job.length > 0) {
          this.initialize(payload)
        }
      },
      period () {
        const payload = {}

        if (this.parttimerManageId.length > 0) {
          payload.parttimerManageId = this.parttimerManageId
        }

        if (this.period && this.customer && this.job.length > 0) {
          this.initialize(payload)
        }
      },
      job () {
        const payload = {}
        this.items = []
        this.itemPaginate = []

        if (this.parttimerManageId.length > 0) {
          payload.parttimerManageId = this.parttimerManageId
        }

        if (this.period && this.customer && this.job.length > 0) {
          this.initialize(payload)
        }
      },
      status (value) {
        this.loading = value === 'loading'
      },
      error (value) {
        this.showSnackBar(value)
      },
    },
    created () {
      const user = JSON.parse(localStorage.getItem('user'))
      const data = JSON.parse(_.get(user, 'data', {}))
      const type = _.get(data, 'type', 0)

      if (type !== 1 && type !== 2 && type !== 3) {
        this.$router.push('/')
      }
    },
    mounted () {
      if (this.$route.query) {
        if (this.$route.query.success) this.showSnackBar('messages.success')
      }
      moment.locale('ja')
      // setting table's headers
      this.getCustomers()

      this.mainHeaders = [
        {
          text: this.$t('table.headers.index'),
          align: 'start',
          value: 'index',
          class: 'pr-0 pl-2 sticky-col font-weight-bold keep-on-scroll',
          sortable: false,
          width: '3rem',
          fixed: true,
        },
        {
          text: this.$t('table.headers.agencyId'),
          align: 'center',
          value: 'parttimeEmployeeId',
          class: 'pr-0 pl-2 sticky-col font-weight-bold keep-on-scroll left-keep-on-scroll-2',
          sortable: false,
          width: '6rem',
          fixed: true,
        },
        {
          text: this.$t('table.headers.name'),
          align: 'start',
          value: 'name',
          class: 'pr-0 pl-2 sticky-col font-weight-bold keep-on-scroll  left-keep-on-scroll-3',
          sortable: false,
          width: '8rem',
          fixed: true,
        },
      ]

      this.getHeaderMount()
    },
    methods: {
      toggle () {
        this.$nextTick(() => {
          if (this.likesAllJob) {
            this.job = []
          } else {
            this.job = this.listJob.slice()
          }
        })
      },
      getTitles () {
        if (this.isOtNightShift) {
          this.titles = [
            { id: 'attendance_confirmation', name: '出勤確認' },
            { id: 'actualStartAt', name: '出勤' },
            { id: 'actualEndAt', name: '退勤' },
            { id: 'normalWorkTime', name: '普通', class: 'especially' },
            { id: 'nightWorkTime', name: '夜勤', class: 'especially' },
            { id: 'workOvertime', name: '残業', class: 'especially' },
            { id: 'nightOtTime', name: '夜勤残業', class: 'especially' },
            { id: 'breakHour', name: '休憩' },
            { id: 'workingHour', name: '就労時間' },
            { id: 'cumulativeTime', name: '累計時間' },
          ]
        } else {
          this.titles = [
            { id: 'attendance_confirmation', name: '出勤確認' },
            { id: 'actualStartAt', name: '出勤' },
            { id: 'actualEndAt', name: '退勤' },
            { id: 'normalWorkTime', name: '普通', class: 'especially' },
            { id: 'nightWorkTime', name: '夜勤', class: 'especially' },
            { id: 'workOvertime', name: '残業', class: 'especially' },
            { id: 'breakHour', name: '休憩' },
            { id: 'workingHour', name: '就労時間' },
            { id: 'cumulativeTime', name: '累計時間' },
          ]
        }
      },
      checkDayWeek (index, startWeek, endWeek) {
        const numberDayOfWeek = moment(endWeek).diff(moment(startWeek), 'days')
        return index <= numberDayOfWeek
      },
      getMonth () {
        if (this.period) {
          return moment(new Date(this.period)).format('YYYY年MMM')
        } else {
          return moment(new Date()).format('YYYY年MMM')
        }
      },
      fomatDateTime (string, plannedAt) {
        return this.convertTimeStringUTC(string, plannedAt)
      },
      getHeaderMount () {
        this.headers = []
        let dayInMount = moment(new Date()).daysInMonth()
        let dayStart = moment(new Date()).startOf('month')
        this.headers = [...this.mainHeaders]
        this.headers.push({
          text: this.getMonth(),
          align: 'center',
          value: 'time',
          class: 'pr-0 pl-2 sticky-col font-weight-bold',
          sortable: false,
          width: '8rem',
          fixed: true,
        })
        if (this.period) {
          dayInMount = moment(new Date(this.period)).daysInMonth()
          dayStart = moment(new Date(this.period)).startOf('month')
        }

        let week = 0
        for (let i = 1; i <= dayInMount; i++) {
          const header = {
            text: i + ' - ' + dayStart.format('ddd'),
            align: 'center',
            value: 'day_' + i,
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '4rem',
            typeTotal: false,
          }
          this.headers.push(header)
          this.headerDays.push(header)
          if (dayStart.day() === 6 || i === dayInMount) {
            week += 1
            const headerTotal = {
              text: '',
              align: 'center',
              value: 'totalWeek_' + week,
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '2rem',
              typeTotal: true,
            }
            this.headers.push(headerTotal)
            this.headerDays.push(headerTotal)
          }
          dayStart = dayStart.add(1, 'days')
        }
        this.headers.push({
          text: this.$t('table.headers.total'),
          align: 'center',
          value: 'total',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '4rem',
        })
        this.headers.push({
          text: this.$t('table.headers.salary'),
          align: 'center',
          value: 'salary',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '4rem',
        })
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      initialize (payload = {}) {
        this.getHeaderMount()
        const tmpOptions = { ...this.options, ...payload }

        if (this.customer) {
          tmpOptions.customerId = this.customer
        }

        if (this.job.length > 0) {
          if (this.likesAllJob) {
            tmpOptions.jobId = this.job.map(el => el.id ? el.id : el)
          } else {
            tmpOptions.jobId = this.job
          }
        }

        if (this.period) {
          tmpOptions.timestamp = this.period
        }

        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('timesheet/getTimeSheets', tmpOptions)
      },
      getCustomers () {
        this.$store.dispatch('timesheet/getCustomers')
      },
      getJobs (id) {
        this.$store.dispatch('timesheet/getJobs', id)
      },
      paginate (array, pageSize, pageNumber) {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      getPeriod () {
        const months = []
        const start = moment('2021-01-01').year()
        const yearNow = moment(new Date()).year()
        const monthNow = moment(new Date()).month()

        for (let year = start; year <= yearNow; year++) {
          for (let i = 0; i < 12; i++) {
            months.push({
              name: `${year}年${this.pad(i + 1)}月`,
              value: `${year}-${this.pad(i + 1)}-01`,
            })

            if (year === yearNow && i === monthNow) {
              break
            }
          }
        }

        return months.reverse()
      },
      pad (d) {
        return d < 10 ? '0' + d.toString() : d.toString()
      },
      search () {
        this.items = []
        const payload = {}

        if (this.parttimerManageId) {
          payload.parttimerManageId = this.parttimerManageId
        }

        this.initialize(payload)
      },
      clear () {
        this.parttimerManageId = ''

        this.search()
      },
      converTime (number) {
        if (number) {
          let startTime = String(parseInt(number))
          let endTime = String(Math.round((number - parseInt(number)) * 60))
          if (startTime.length === 1) {
            startTime = '0' + startTime + ':'
          } else if (startTime.length === 2) {
            startTime += ':'
          }
          if (endTime.length === 1) {
            endTime = '0' + endTime
          }
          return startTime + endTime
        } else {
          return '00:00'
        }
      },
      convertTimeStringUTC (actualTime, plannedStartAt) {
        let time = '00:00'
        if (
          moment.utc(new Date(plannedStartAt)).format('YYYY-MM-DD') ===
          moment.utc(new Date(actualTime)).format('YYYY-MM-DD')
        ) {
          time = moment
            .utc(new Date(actualTime))
            .format('HH:mm')
        } else {
          time = this.handleCalculatorTime(moment.utc(new Date(actualTime)).format('HH:mm'))
        }
        return time
      },
      handleCalculatorTime (time) {
        let setTime = String(parseInt(time.slice(0, 2)) + 24)
        setTime += ':' + String(time.slice(3, 5))
        return setTime
      },
    },
  }
</script>

<style scoped lang="sass">
.time-col
  border-bottom: none !important

.right-border-col
  border-right: thin solid rgba(0, 0, 0, 0.12)!important

.bottom-col
  border-bottom: thin solid rgba(0, 0, 0, 0.12)!important

.attendancConfirm
  color: #5CA6D2!important
.total-col
  border-right: thin solid rgba(0, 0, 0, 0.12)!important
  border-left: thin solid rgba(0, 0, 0, 0.12)!important
.infoWarp
  padding-top: 20px

.lblText
  margin-top: 10px
.rowAttendenceRecords
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 10px!important
  height: 100%

  div
    height: 100%
    display: flex
    justify-content: space-between
    align-items: center
    span
      padding: 0 10px
td
  text-align: center
.especially
  color: #E43E08
.v-data-table
  tbody
    tr:hover:not(.v-data-table__expanded__content)
      background: #ffffff !important
.attendanceConfirmation
  justify-content: center!important
.rowAttendenceRecords
  div
    span
      width: 70px

</style>

// keep 4 field in table on scroll
<style scoped lang="scss">
  .v-data-table ::v-deep .keep-on-scroll {
    position: sticky !important;
    left: 0 !important;
    z-index: 9998 !important;
    background: white !important;
  }

  .v-data-table ::v-deep .left-keep-on-scroll-2 {
    left: 3rem !important
  }

  .v-data-table ::v-deep .left-keep-on-scroll-3 {
    left: 9rem !important
  }

  .v-data-table ::v-deep thead th:nth-child(4){
    position: sticky !important;
    left: 17rem !important;
    z-index: 9998 !important;
    background: white !important;
  }

  //tbody
  .v-data-table ::v-deep .body-keep-on-scroll-2 {
    left: 3rem !important;
  }

  .v-data-table ::v-deep .body-keep-on-scroll-3 {
    left: 9rem !important;
  }

  .v-data-table ::v-deep .body-keep-on-scroll-4 {
    left: 17rem !important;
  }
  .zindex-10000 {
    z-index: 10000;
  }
</style>
